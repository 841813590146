import React, { FC, useEffect, useState } from 'react';
import List from '../../components/Products/List';
import Layout from '../../components/layout/Layout';
import Breadcrumb from '../../components/common/Breadcrumb';
import ShopList from '../../components/Products/ShopList';
import { useStaticQuery, graphql, Script } from 'gatsby';
import Seo from '../../components/Seo';
import { getHead } from '../../services/rankMathServices';
import parse from 'html-react-parser';
import Clickmagic from '../../components/common/ClickMagic/Clickmagic';
import { removeMetaTag } from '../../utils/helper';

const AllPeptide: FC<any> = (props) => {
  const { data } = props;
  const seoInfo = data.allWpPage.nodes[0].seo.fullHead;
  const [info, setInfo] = useState(null);
  const peptideData = data?.allWpPage?.nodes[0];
  useEffect(() => {
    // getHead("all-peptides").then((data: any) => { setInfo(data.head) })
  }, []);
  return (
    <>
      {/* {info && <Seo info={info} />} */}
      <Layout>
        <ShopList pageData={peptideData} products={data?.products.nodes} />
      </Layout>
    </>
  );
};

export default AllPeptide;

export const query = graphql`
  {
    allWpPage(filter: { slug: { eq: "all-peptides" } }) {
      nodes {
        id
        seo {
          fullHead
        }
        allPeptideData {
          adv1Image390X543 {
            node {
              sourceUrl
            }
          }
          adv2Image390X543 {
            node {
              sourceUrl
            }
          }
          advBannerImageDesktop430X226 {
            node {
              sourceUrl
            }
          }
          advBannerImageMobile772X562 {
            node {
              sourceUrl
            }
          }
          advBannerText
          advBannerTextList
          bannerImage {
            node {
              sourceUrl
            }
          }
          headerBannerImageDesktop1000X452 {
            node {
              sourceUrl
            }
          }
          headerBannerImageMobile444X360 {
            node {
              sourceUrl
            }
          }
          headerBannerText
        }
      }
    }
    products: allWcProducts(sort: { date_created: ASC }) {
      nodes {
        id: wordpress_id
        name
        slug
        regular_price
        stock_quantity
        price
        images {
          id
          name
          src
          alt
        }
        stock_status
        type
        product_variations {
          id
          name
          regular_price
          stock_quantity
          price
          image {
            id
            name
            src
            alt
          }
          stock_status
          type
        }
        grouped_products_nodes {
          id
          name
          slug
          regular_price
          stock_quantity
          price
          images {
            id
            name
            src
            alt
          }
          stock_status
          type
          product_variations {
            id
            name
            regular_price
            stock_quantity
            price
            image {
              id
              name
              src
              alt
            }
            stock_status
            type
          }
        }
      }
    }
  }
`;

export const Head = (props: any) => {
  const { data } = props;
  const seoInfo = data.allWpPage.nodes[0].seo.fullHead;
  const parseHtml: any = parse(seoInfo);

  const index =
    parseHtml &&
    parseHtml?.length > 0 &&
    parseHtml.findIndex(
      (item: any) => item?.props?.type === 'application/ld+json'
    );

  if (index > -1) {
    parseHtml.splice(index, 1);
  }

  removeMetaTag(parseHtml, 'og:url');

  return (
    <>
      {parseHtml}
      <meta property="og:url" content={`${process.env.GATSBY_CLIENT_URI}/all-peptides/`} />
      <Seo info={seoInfo} />
    </>
  );
};

// export const query = graphql`
// 	{
// 		allWpPage(filter: { slug: { eq: "all-peptides" } }) {
// 			nodes {
// 				id
// 			}
// 		}
// 	}
// `;
